import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { getFormat } from "./helperFunctions";

export let tempURI = "http://localhost:3000";
if (process.env.NODE_ENV === "production") {
  tempURI = process.env.PROD_URL || "https://anything-jade.vercel.app";
}

export function getRootDomain(host) {
  if (host.includes("localhost")) {
    const [hostname, port] = host.split(":");
    return `http://localhost${port ? `:${port}` : ""}`;
  }
  const parts = host.split(".");
  if (parts.length <= 2) {
    return host;
  }
  return "https://" + parts.slice(-2).join(".");
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const fetcher = (...args) => {
  return fetch(...args)
    .then((res) => res.json())
    .catch((error) => console.log(error));
};

export const getTypeFromThing = (thing) => {
  let { isType, type, type_of = [] } = thing;
  if (isType) {
    type = thing.slug;
    return type;
  }
  if (type?.slug) {
    console.log("THISHASSSLUG", type.slug);
    type = getFormat(type.slug); //Paramsthing because the name may be the category
    return type;
  }
  let typeOf = type_of?.length > 0 ? type_of?.[0]?.name : thing.name;
  type = getFormat(typeOf || thing?.name); //Paramsthing because the name may be the category
  return type;
};

export const extractLinks = (text) => {
  const regex = /(\b(https?|ftp|file):\/\/)?[-A-Z0-9+&@#/%=~_|$?!:,.]*[A-Z0-9+&@#/%=~_|$]/gi;
  let matches = text.match(regex);
  return matches;
  console.log("MATCHESSS", matches, regex);
  if (matches) {
    return matches.map((url) => url.split("?")[0]);
  }
  return [];
};

export function trimUrl(url) {
  let trimmed = url;
  // Remove HTTP parameters and hash
  // let trimmed = url.split(/[?#]/)[0];
  // Remove trailing slash
  trimmed = trimmed.endsWith("/") ? trimmed.slice(0, -1) : trimmed;
  // Remove protocol (http://, https://)
  return trimmed.replace(/^https?:\/\//, "");
}

export function convertElementsConnectionToElements(elementsConnection) {
  return (
    elementsConnection?.edges?.map((edge) => {
      return { ...edge, ...edge.node, elementsConnection: undefined };
    }) || []
  );
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}
