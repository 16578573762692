import { Sparkle, Clapperboard, MonitorPlay, MonitorStop, Gamepad2, BookOpen, BookAudio, UserCircle, Drama, Heart, Podcast } from "lucide-react";

export const categoriesList = {
  movies: {
    name: "Movies",
    button_text: "Watchlist",
    wikidata_id: "Q11424",
    thing_style: 1,
    in_sidebar: true,
    in_library: true,
    menu: [
      {
        text: "Add to Watchlist",
        text_active: "Watchlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Watched",
        text_active: "Watched",
        web_icon: <Sparkle />,
        icon: "eye",
        logDate: true,
      },
    ],
    web_icon: (props) => <Clapperboard {...props} />,
    icon: "film",
    color: "#FFBA34",
    image_aspect_ratio: 40 / 27,
    image_aspect_ratio_lower: 27 / 40,
    header_image_width: 0.4,
    background_color: "#E0FBEB",
    hasRating: true,
    backdrop_aspect_ratio: 9 / 16,
    id: "movies-watchlist",
    thing: { name: "Movies", id: "c21667d3-8a2e-453f-b0db-1085285da90d" },
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "Now Playing", query: "now_playing_children" },
      { title: "A to Z", query: "a_to_z_children" },
      { title: "Recently Added", query: "new_children" },
    ],
    sections: [
      { name: "Popular", query: "popular_children" },
      { name: "Recently Added", query: "new_children" },
      { name: "Top Rated", query: "top_rated" },
      { name: "Upcoming", query: "upcoming_children" },
      { name: "A to Z", query: "a_to_z_children" },
      { name: "Now Playing", query: "now_playing_children" },
    ],
    subheader: [
      { wikidata_id: "P577", show: "dataValue", type: "date", format: "year" },
      { wikidata_id: "P2047", show: "dataValue", type: "timeHoursMins" },
      { wikidata_id: "P136", show: "thingsNames" },
      { wikidata_id: "P7", show: "dataValue" },
    ],
    formats: ["movie", "film", "movies"],
    web_props_layout: {
      main_header: {
        style: "thingListCarousel",
        hideLabel: true,
        horizontal: true,
        scrollable: true,
        title: "Cast",
        ids: [
          {
            label: "Cast members",
            wikidata_id: "P161",
            inverse: 0,
            show: "things",
            max: 10,
            title: "name",
            hasQualifierWikidataId: "P453",
            hideLabel: true,
          },
          {
            label: "Directors",
            wikidata_id: "P57",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
            hideLabel: true,
          },
          {
            label: "Producers",
            wikidata_id: "P162",
            inverse: 0,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
            hideLabel: true,
          },
          {
            label: "Executive Producers",
            wikidata_id: "P1431",
            inverse: 0,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
            hideLabel: true,
          },
          // { label: "TMDB ID", wikidata_id: "P4985", inverse: 1, show: "dataValue", hasQualifierWikidataId: "P106" },
          // { label: "Type", wikidata_id: "P31", inverse: 0, show: "thingsNames" },
        ],
      },
      second_header: {
        style: "videos",
        hideLabel: true,
        horizontal: true,
        scrollable: true,
        title: "Cast",
        ids: [
          {
            label: "Videos",
            wikidata_id: "P10",
            inverse: 0,
            show: "images",
            has_media: true,
            isVideos: true,
            hideLabel: true,
          },
        ],
      },
      body_header: {
        style: "thingListCarousel",
        horizontal: true,
        horizontal_scrollable: true,
        scrollable: true,
        title: "Cast Members",
        ids: [
          {
            label: "Cast members",
            wikidata_id: "P161",
            inverse: 0,
            show: "things",
            max: 10,
            title: "name",
            hasQualifierWikidataId: "P453",
            hideLabel: true,
          },
          {
            label: "Director",
            wikidata_id: "P57",
            inverse: 0,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Producer",
            wikidata_id: "P162",
            inverse: 0,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Executive Producer",
            wikidata_id: "P1431",
            inverse: 0,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
        ],
      },
      right_sidebar: {
        style: "infoblock",
        // direction: 'row',
        horizontal: true,
        scrollable: true,
        title: "About",
        ids: [
          {
            label: "Genre",
            wikidata_id: "P136",
            inverse: 0,
            show: "thingsNames",
            hideImage: true,
          },
          {
            label: "Release Date",
            wikidata_id: "P577",
            inverse: 1,
            show: "dataValue",
            type: "date",
          },
          { label: "Status", wikidata_id: "P7", inverse: 0, show: "dataValue" },
          {
            label: "Box Office",
            wikidata_id: "P2142",
            inverse: 0,
            show: "dataValue",
            type: "money",
          },
          {
            label: "Cost",
            wikidata_id: "P2130",
            inverse: 1,
            show: "dataValue",
            type: "money",
          },
          {
            label: "Runtime",
            wikidata_id: "P2047",
            inverse: 0,
            show: "dataValue",
            type: "duration",
          },
          {
            label: "Homepage",
            wikidata_id: "P856",
            inverse: 0,
            show: "dataValue",
            type: "link",
          },
          {
            label: "Available on",
            wikidata_id: "P4947",
            show: "watchProvider",
          },

          // { label: "Director", wikidata_id: "P57", inverse: 0, show: "thingsNames", hasQualifierWikidataId: "P106" },
          // { label: "Producer", wikidata_id: "P162", inverse: 0, show: "thingsNames", hasQualifierWikidataId: "P106" },
          // { label: "Executive Producer", wikidata_id: "P1431", inverse: 0, show: "thingsNames", hasQualifierWikidataId: "P106" },
        ],
      },
    },
    things_props_layout: [
      {
        style: "infoblock",
        // direction: 'row',
        horizontal: true,
        scrollable: true,
        title: "About",
        ids: [
          {
            label: "Genre",
            wikidata_id: "P136",
            inverse: 0,
            show: "thingsNames",
            hideImage: true,
          },
          {
            label: "Release Date",
            wikidata_id: "P577",
            inverse: 1,
            show: "dataValue",
            type: "date",
          },
          { label: "Status", wikidata_id: "P7", inverse: 0, show: "dataValue" },
          {
            label: "Box Office",
            wikidata_id: "P2142",
            inverse: 0,
            show: "dataValue",
            type: "money",
          },
          {
            label: "Cost",
            wikidata_id: "P2130",
            inverse: 1,
            show: "dataValue",
            type: "money",
          },
          {
            label: "Runtime",
            wikidata_id: "P2047",
            inverse: 0,
            show: "dataValue",
            type: "duration",
          },
          {
            label: "Homepage",
            wikidata_id: "P856",
            inverse: 0,
            show: "dataValue",
            type: "link",
          },
        ],
      },
      {
        style: "thingList",
        horizontal: true,
        horizontal_scrollable: true,
        scrollable: true,
        title: "Cast Members",
        ids: [
          {
            label: "Cast members",
            wikidata_id: "P161",
            inverse: 0,
            show: "things",
            max: 10,
            title: "name",
            hasQualifierWikidataId: "P453",
            hideLabel: true,
          },
        ],
      },
      {
        style: "thingList",
        horizontal: true,
        scrollable: true,
        title: "Extras",
        ids: [
          {
            label: "Videos",
            wikidata_id: "P10",
            inverse: 0,
            show: "images",
            has_media: true,
            isVideos: true,
            hideLabel: true,
          },
        ],
      },

      {
        style: "watchProvider",
        horizontal: true,
        scrollable: false,
        title: "Available on",
        hideTitle: true,
        ids: [
          {
            label: "Available on",
            wikidata_id: "P4947",
            show: "watchProvider",
            hideLabel: true,
          },
        ],
      },
      {
        style: "thingList",
        // direction: 'row',
        horizontal: true,
        scrollable: true,
        title: "Crew",
        ids: [
          {
            label: "Director",
            wikidata_id: "P57",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Producer",
            wikidata_id: "P162",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Executive Producer",
            wikidata_id: "P1431",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
          },
        ],
      },
    ],
    thing_props_map: [
      {
        label: "Cast members",
        wikidata_id: "P161",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      {
        label: "Videos",
        wikidata_id: "P10",
        inverse: 0,
        show: "images",
        has_media: true,
      },
      {
        label: "Box Office",
        wikidata_id: "P2142",
        inverse: 0,
        show: "dataValue",
      },
      {
        label: "Genre",
        wikidata_id: "P136",
        inverse: 0,
        show: "things",
        hideImage: true,
      },
      {
        label: "Release Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Cost", wikidata_id: "P2130", inverse: 1, show: "dataValue" },
      {
        label: "Director",
        wikidata_id: "P57",
        inverse: 0,
        show: "things",
        title: "name",
        hasQualifierWikidataId: "P106",
      },
      {
        label: "Runtime",
        wikidata_id: "P2047",
        inverse: 0,
        show: "dataValue",
        type: "timeHoursMins",
      },
      { label: "Status", wikidata_id: "P7", inverse: 0, show: "dataValue" },
      { label: "Homepage", wikidata_id: "P856", inverse: 0, show: "dataValue" },
      {
        label: "Producer",
        wikidata_id: "P162",
        inverse: 0,
        show: "things",
        title: "name",
        hasQualifierWikidataId: "P106",
      },
      {
        label: "Executive Producer",
        wikidata_id: "P1431",
        inverse: 0,
        show: "things",
        title: "name",
        hasQualifierWikidataId: "P106",
      },
      {
        label: "Available on",
        wikidata_id: "P4947",
        show: "watchProvider",
        hideLabel: true,
      },
    ],
  },

  "tv-series": {
    name: "TV Series",
    button_text: "Watchlist",
    wikidata_id: "Q5398426",
    menu: [
      {
        text: "Add to Watchlist",
        text_active: "Watchlist",
        web_icon: <Sparkle />,
        icon: "eye",
      },
      {
        text: "Mark as Watched",
        text_active: "Watched",
        web_icon: <Sparkle />,
        icon: "eye",
      },
    ],
    web_icon: (props) => <MonitorPlay {...props} />,
    in_sidebar: true,
    in_library: true,

    icon: "sparkles.tv",
    color: "#308FFF",
    header_image_width: 0.4,
    background_color: "#D9EBFF",
    id: "tv-series-watchlist",
    image_aspect_ratio: 40 / 27,
    hasRating: true,
    thing: { name: "TV Series", id: "8600f356-2338-4a0f-bbc8-3b695210e888" },
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "A to Z", query: "a_to_z_children" },
    ],
    sections: [
      { name: "Popular", query: "popular_children" },
      { name: "Recently Added", query: "new_children" },
      { name: "Top Rated", query: "top_rated" },
      { name: "Upcoming", query: "upcoming" },
    ],
    subParts: {
      name: "Seasons",
      topLevelLabel: "season",
      secondLevel: { name: "Episodes", wikidata_id: "P361" },
    },
    formats: ["television series", "tv series", "tv-series"],
    subheader: [
      { wikidata_id: "P577", show: "dataValue", type: "date", format: "year" },
      { wikidata_id: "P7", show: "dataValue" },
    ],
    things_props_layout: [
      {
        style: "infoblock",
        // direction: 'row',
        horizontal: true,
        scrollable: true,
        title: "About",
        ids: [
          {
            label: "Genre",
            wikidata_id: "P136",
            inverse: 0,
            show: "thingsNames",
            hideImage: true,
          },
          {
            label: "Release Date",
            wikidata_id: "P577",
            inverse: 1,
            show: "dataValue",
            type: "date",
          },
          { label: "Status", wikidata_id: "P7", inverse: 0, show: "dataValue" },
          {
            label: "Box Office",
            wikidata_id: "P2142",
            inverse: 0,
            show: "dataValue",
            type: "money",
          },
          {
            label: "Cost",
            wikidata_id: "P2130",
            inverse: 1,
            show: "dataValue",
            type: "money",
          },
          {
            label: "Runtime",
            wikidata_id: "P2047",
            inverse: 0,
            show: "dataValue",
            type: "duration",
          },
          {
            label: "Homepage",
            wikidata_id: "P856",
            inverse: 0,
            show: "dataValue",
            type: "link",
          },
        ],
      },
      {
        style: "thingList",
        horizontal: true,
        horizontal_scrollable: true,
        scrollable: true,
        title: "Cast Members",
        ids: [
          {
            label: "Cast members",
            wikidata_id: "P161",
            inverse: 0,
            show: "things",
            max: 10,
            title: "name",
            hasQualifierWikidataId: "P453",
            hideLabel: true,
          },
        ],
      },
      {
        style: "thingList",
        horizontal: true,
        scrollable: true,
        title: "Extras",
        ids: [
          {
            label: "Videos",
            wikidata_id: "P10",
            inverse: 0,
            show: "images",
            has_media: true,
            isVideos: true,
            hideLabel: true,
          },
        ],
      },

      {
        style: "watchProvider",
        horizontal: true,
        scrollable: false,
        title: "Available on",
        hideTitle: true,
        ids: [
          {
            label: "Available on",
            wikidata_id: "P4947",
            show: "watchProvider",
            hideLabel: true,
          },
        ],
      },
      {
        style: "thingList",
        // direction: 'row',
        horizontal: true,
        scrollable: true,
        title: "Crew",
        ids: [
          {
            label: "Director",
            wikidata_id: "P57",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Producer",
            wikidata_id: "P162",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Executive Producer",
            wikidata_id: "P1431",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
          },
        ],
      },
    ],
    thing_props_map: [
      {
        label: "Cast members",
        wikidata_id: "P161",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "First Air Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Last Air Date",
        wikidata_id: "P582",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Episode Run Time",
        wikidata_id: "P2047",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Homepage", wikidata_id: "P856", inverse: 0, show: "dataValue" },
      {
        label: "Videos",
        wikidata_id: "P10",
        inverse: 0,
        show: "images",
        has_media: true,
      },
      { label: "Status", wikidata_id: "P7", inverse: 0, show: "dataValue" },
    ],
  },
  musicians: {
    name: "Musicians",
    button_text: "Favorites",
    wikidata_id: "Q639669",
    menu: [
      {
        text: "Mark as Favorite",
        text_active: "Favorite",
        web_icon: <Sparkle />,
        icon: "heart",
      },
    ],
    icon: "music.note",
    color: "#007AFF",
    image_aspect_ratio: 40 / 27,
    header_image_width: 0.4,
    background_color: "#D9EBFF",
    id: "musician-favorites",
    hasRating: true,
    thing: { name: "Musicians", id: "d5bcc39c-a88f-4c8a-9d00-fc03558de52b" },
    sections: [
      { name: "Popular", query: "popular_children" },
      { name: "Recently Added", query: "new_children" },
      { name: "Top Rated", query: "top_rated" },
      { name: "Upcoming", query: "upcoming" },
    ],
    subParts: {
      name: "Albums",
      topLevelLabel: "album",
      secondLevel: { name: "Song", wikidata_id: "P361" },
    },
    formats: ["Musician"],
    thing_props_map: [
      {
        label: "Cast members",
        wikidata_id: "P161",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "First Air Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Last Air Date",
        wikidata_id: "P582",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Episode Run Time",
        wikidata_id: "P2047",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Homepage", wikidata_id: "P856", inverse: 0, show: "dataValue" },
      {
        label: "Videos",
        wikidata_id: "P10",
        inverse: 0,
        show: "images",
        has_media: true,
      },
    ],
  },

  books: {
    name: "Books",
    button_text: "Readlist",
    wikidata_id: "Q7725634",
    menu: [
      {
        text: "Add to Readlist",
        text_active: "Readlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Read",
        text_active: "Read",
        web_icon: <Sparkle />,
        icon: "book",
        logDate: true,
      },
      {
        text: "Mark as Abandoned",
        text_active: "Abandoned",
        web_icon: <Sparkle />,
        icon: "book.fill",
      },
    ],
    web_icon: (props) => <BookOpen {...props} />,
    in_sidebar: true,
    in_library: true,

    color: "#A2845E",
    icon: "book",
    header_image_width: 0.4,
    image_aspect_ratio: 40 / 27,
    sections: [
      { name: "Popular", query: "popular_children" },
      { name: "Recently Added", query: "new_children" },
      { name: "Top Rated", query: "top_rated" },
      { name: "Upcoming", query: "upcoming_children" },
      { name: "A to Z", query: "children" },
    ],
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "A to Z", query: "a_to_z_children" },
      { title: "Recently Added", query: "new_children" },
    ],
    // color: "#00C7BE",
    background_color: "#C2EAE8",
    id: "books-readlist",
    thing: { name: "Books", id: "3345bbcf-9b5a-462f-afd0-35f1a0448119" },
    formats: ["book", "literary work", "books"],
    image_aspect_ratio: 40 / 27,
    subheader: [
      { wikidata_id: "P577", show: "dataValue", type: "date", format: "year" },
      { wikidata_id: "P136", show: "thingsNames" },
      { label: "Authors", wikidata_id: "P50", show: "thingsNames" },
    ],
    thing_props_map: [
      {
        label: "Authors",
        wikidata_id: "P50",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "Release Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Characters", wikidata_id: "P674", inverse: 0, show: "things" },
    ],
  },

  "video-games": {
    name: "Video Games",
    button_text: "Playlist",
    wikidata_id: "Q7889",
    menu: [
      {
        text: "Add to Playlist",
        text_active: "Playlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Playing",
        text_active: "Playing",
        web_icon: <Sparkle />,
        icon: "gamecontroller",
      },
      {
        text: "Mark as Played",
        text_active: "Played",
        web_icon: <Sparkle />,
        icon: "gamecontroller.fill",
      },
    ],
    // web_icon: <Gamepad2 />,
    web_icon: (props) => <Gamepad2 {...props} />,
    in_sidebar: true,
    in_library: false,

    icon: "gamecontroller",
    color: "#FF3434",
    formats: ["games", "game", "video game", "video games", "video-game"],
    background_color: "#C2EAE8",
    header_image_width: 0.4,
    image_aspect_ratio: 40 / 27,
    id: "games",
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "A to Z", query: "a_to_z_children" },
    ],
    sections: [
      { name: "Popular", query: "popular_children" },
      { name: "Recently Added", query: "new_children" },
      { name: "Top Rated", query: "top_rated" },
      { name: "Upcoming", query: "upcoming_children" },
      { name: "A to Z", query: "children" },
    ],
    subheader: [
      { wikidata_id: "P577", show: "dataValue", type: "date", format: "year" },
      { wikidata_id: "P2047", show: "dataValue", type: "timeHoursMins" },
      { wikidata_id: "P136", show: "thingsNames" },
      { wikidata_id: "P7", show: "dataValue" },
    ],
    thing: { name: "video game", id: "4aaf4c1e-923d-450d-a606-134606ec8f07" },

    thing_props_map: [
      {
        label: "Cast members",
        wikidata_id: "P161",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      {
        label: "Videos",
        wikidata_id: "P10",
        inverse: 0,
        show: "images",
        has_media: true,
      },
      {
        label: "Artwork",
        wikidata_id: "P8",
        inverse: 0,
        show: "images",
        has_media: true,
      },
      {
        label: "Screenshots",
        wikidata_id: "P9",
        inverse: 0,
        show: "images",
        has_media: true,
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "Release Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Available on",
        wikidata_id: "P400",
        inverse: 0,
        show: "things",
      },
      { label: "Status", wikidata_id: "P7", inverse: 0, show: "dataValue" },
    ],
  },
  audiobooks: {
    name: "Audiobooks",
    button_text: "Listenlist",
    wikidata_id: "Q106833",
    menu: [
      {
        text: "Add to Listenlist",
        text_active: "Listenlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Listening",
        text_active: "Listening",
        web_icon: <Sparkle />,
        icon: "beats.headphones",
      },
      {
        text: "Mark as Abandoned",
        text_active: "Abandoned",
        web_icon: <Sparkle />,
        icon: "headphones",
      },
    ],
    // web_icon: <BookAudio />,
    web_icon: (props) => <BookAudio {...props} />,
    in_sidebar: true,
    in_library: true,

    icon: "airpodsmax",
    color: "#DF8301",
    image_aspect_ratio: 1,
    header_image_width: 0.5,

    background_color: "#C2EAE8",
    id: "actor-favorite",
    thing: { name: "Audiobooks", id: "dd9736e7-4d9d-4b43-9504-c5070629b4e8" },
    formats: ["audiobook", "audiobooks"],
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "A to Z", query: "a_to_z_children" },
    ],
    subheader: [
      { wikidata_id: "P577", show: "dataValue", type: "date", format: "year" },
      { wikidata_id: "P136", show: "thingsNames" },
      { label: "Authors", wikidata_id: "P50", show: "thingsNames" },
    ],
    thing_props_map: [
      {
        label: "Authors",
        wikidata_id: "P50",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "Release Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
    ],
  },
  podcasts: {
    name: "Podcasts",
    button_text: "Favorites",
    wikidata_id: "Q24634210",
    in_sidebar: true,
    in_library: true,

    // web_icon: <Podcast />,
    web_icon: (props) => <Podcast {...props} />,
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "Now Playing", query: "now_playing_children" },
      { title: "A to Z", query: "a_to_z_children" },
      { title: "Recently Added", query: "new_children" },
    ],
    menu: [
      {
        text: "Add to Listenlist",
        text_active: "Listenlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Listening",
        text_active: "Listening",
        web_icon: <Sparkle />,
        icon: "eye",
        logDate: true,
      },
    ],

    // menu: [
    //   {
    //     text: "Mark as Favorite",
    //     text_active: "Favorite",
    //     web_icon: <Sparkle />,
    //     icon: "heart",
    //   },
    // ],
    icon: "mic",
    color: "#00C7BE",
    background_color: "#C2EAE8",
    header_image_width: 0.5,
    image_aspect_ratio: 1,
    id: "podcasts",
    thing: { name: "Podcasts", id: "204b93d1-5549-4929-9163-aa986c182d74" },
    formats: ["podcasts"],
    home_sections: [
      { title: "Popular", query: "popular_children" },
      { title: "Upcoming", query: "upcoming_children" },
      { title: "Now Playing", query: "now_playing_children" },
      { title: "A to Z", query: "a_to_z_children" },
      { title: "Recently Added", query: "new_children" },
    ],

    // things: [],
    // parent_thing: {}
  },
  actors: {
    name: "Actors",
    button_text: "Favorites",
    wikidata_id: "Q33999",
    menu: [
      {
        text: "Mark as Favorite",
        text_active: "Favorite",
        web_icon: <Sparkle />,
        icon: "heart",
      },
    ],
    icon: "theatermasks",
    color: "#00C7BE",
    background_color: "#C2EAE8",
    header_image_width: 0.4,
    image_aspect_ratio: 27 / 40,
    id: "actor-favorite",
    thing: { name: "actor", id: "fdbf8d12-8666-4c49-be56-f253b90c5dd8" },
    formats: ["actors", "actor"],
    thing_props_map: [
      {
        label: "Filmography",
        wikidata_id: "P161",
        inverse: 2,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Videos", wikidata_id: "P10", inverse: 0, show: "things" },
      {
        label: "Box Office",
        wikidata_id: "P2142",
        inverse: 0,
        show: "dataValue",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "Release Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Cost", wikidata_id: "P2130", inverse: 1, show: "dataValue" },
      { label: "Director", wikidata_id: "P57", inverse: 0, show: "things" },
    ],
  },
  genres: {
    name: "Genres",
    button_text: "Favorites",
    wikidata_id: "Q483394",
    type: "subcategory",
    skip_library: true,
    menu: [
      {
        text: "Mark as Favorite",
        text_active: "Favorite",
        web_icon: <Sparkle />,
        icon: "heart",
      },
    ],
    // web_icon: <Drama />,
    web_icon: (props) => <Drama {...props} />,

    icon: "theatermasks",
    color: "#D634FF",
    background_color: "#C2EAE8",
    header_image_width: 0.4,
    image_aspect_ratio: 27 / 40,
    id: "genres",
    thing: { name: "Genres", id: "369ebd4d-e29f-4d5d-981f-d5bc87224889" },
    formats: ["genre", "genres"],
    thing_props_map: [
      {
        label: "Filmography",
        wikidata_id: "P161",
        inverse: 2,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Videos", wikidata_id: "P10", inverse: 0, show: "things" },
      {
        label: "Box Office",
        wikidata_id: "P2142",
        inverse: 0,
        show: "dataValue",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "Release Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Cost", wikidata_id: "P2130", inverse: 1, show: "dataValue" },
      { label: "Director", wikidata_id: "P57", inverse: 0, show: "things" },
    ],
  },
  people: {
    name: "People",
    button_text: "Favorites",
    wikidata_id: "Q5",
    menu: [],
    // web_icon: <UserCircle />,
    web_icon: (props) => <UserCircle {...props} />,
    in_sidebar: true,
    in_library: true,

    icon: "theatermasks",
    color: "#16CD44",
    background_color: "#C2EAE8",
    header_image_width: 0.4,
    image_aspect_ratio: 40 / 27,
    id: "people",
    thing: { name: "human", id: "ba5e5d21-3deb-4301-b17c-f8f092f44929" },
    formats: ["person", "human", "people", "People"],
    menu: [
      {
        text: "Add to Favorites",
        text_active: "Favorite",
        web_icon: <Heart />,
        icon: "sparkles",
      },
    ],
    home_sections: [
      // { title: "Popular", query: "popular_children" },
      { title: "A to Z", query: "a_to_z_children" },
      { title: "Recently Added", query: "new_children" },
    ],
    web_props_layout: {
      main_header: {
        style: "thingNames",
        hideLabel: true,
        horizontal: true,
        scrollable: true,
        title: "Crew",
        ids: [
          {
            label: "Cast members",
            wikidata_id: "P161",
            inverse: 2,
            show: "thingsNames",
            max: 10,
            title: "name",
            hasQualifierWikidataId: "P453",
            hideLabel: true,
          },
          {
            label: "Director",
            wikidata_id: "P57",
            inverse: 0,
            show: "things",
            title: "name",
            hasQualifierWikidataId: "P106",
            hideLabel: true,
          },
          {
            label: "Producer",
            wikidata_id: "P162",
            inverse: 1,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Executive Producer",
            wikidata_id: "P1431",
            inverse: 1,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "TMDB ID",
            wikidata_id: "P4985",
            inverse: 1,
            show: "dataValue",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Type",
            wikidata_id: "P31",
            inverse: 0,
            show: "thingsNames",
          },
        ],
      },
      body_header: {
        style: "thingListCarousel",

        horizontal: true,
        horizontal_scrollable: true,
        scrollable: true,
        title: "Cast Members",
        ids: [
          {
            label: "Cast members",
            wikidata_id: "P161",
            inverse: 2,
            show: "things",
            max: 10,
            title: "name",
            hasQualifierWikidataId: "P453",
            hideLabel: true,
          },
        ],
      },
      right_sidebar: {
        style: "infoblock",
        // direction: 'row',
        horizontal: true,
        scrollable: true,
        title: "About",
        ids: [
          {
            label: "Director",
            wikidata_id: "P57",
            inverse: 1,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Producer",
            wikidata_id: "P162",
            inverse: 1,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Executive Producer",
            wikidata_id: "P1431",
            inverse: 1,
            show: "thingsNames",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "TMDB ID",
            wikidata_id: "P4985",
            inverse: 1,
            show: "dataValue",
            hasQualifierWikidataId: "P106",
          },
          {
            label: "Type",
            wikidata_id: "P31",
            inverse: 0,
            show: "thingsNames",
          },
        ],
      },
    },
    thing_props_map: [
      {
        label: "Filmography",
        wikidata_id: "P161",
        inverse: 2,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      {
        label: "Books & Audiobooks",
        wikidata_id: "P50",
        inverse: 1,
        show: "things",
      },
      {
        label: "Pseudonyms",
        wikidata_id: "P742",
        inverse: 0,
        show: "dataValue",
      },
      { label: "Occupations", wikidata_id: "P106", inverse: 0, show: "things" },
      { label: "Birthday", wikidata_id: "P569", inverse: 0, show: "dataValue" },
      {
        label: "Director",
        wikidata_id: "P57",
        inverse: 1,
        show: "things",
        title: "name",
        hasQualifierWikidataId: "P106",
      },
      {
        label: "Producer",
        wikidata_id: "P162",
        inverse: 1,
        show: "things",
        title: "name",
        hasQualifierWikidataId: "P106",
      },
      {
        label: "Executive Producer",
        wikidata_id: "P1431",
        inverse: 1,
        show: "things",
        title: "name",
        hasQualifierWikidataId: "P106",
      },
    ],
  },
  episode: {
    name: "Episodes",
    thing_style: "episode",
    formats: ["episode", "episodes"],
    skip_library: true,
    sections: [],
    web_icon: (props) => <MonitorStop {...props} />,

    color: "#0F172A",
    menu: [
      {
        text: "Add to Watchlist",
        text_active: "Watchlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Watched",
        text_active: "Watched",
        web_icon: <Sparkle />,
        icon: "eye",
        logDate: true,
      },
    ],
    thing: { name: "Episode", id: "5485d628-405f-4031-92f4-2feca475fd4c" },
    header_image_width: 0.65,
    image_aspect_ratio: 9 / 16,
    thing_props_map: [
      {
        label: "Cast members",
        wikidata_id: "P161",
        inverse: 0,
        show: "things",
        max: 10,
        title: "name",
        hasQualifierWikidataId: "P453",
      },
      { label: "Genre", wikidata_id: "P136", inverse: 0, show: "things" },
      {
        label: "First Air Date",
        wikidata_id: "P577",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Last Air Date",
        wikidata_id: "P582",
        inverse: 1,
        show: "dataValue",
      },
      {
        label: "Episode Run Time",
        wikidata_id: "P2047",
        inverse: 1,
        show: "dataValue",
      },
      { label: "Homepage", wikidata_id: "P856", inverse: 0, show: "dataValue" },
      {
        label: "Videos",
        wikidata_id: "P10",
        inverse: 0,
        show: "images",
        has_media: true,
      },
    ],
  },
  album: {
    name: "Albums",
    button_text: "Favorites",
    icon: "theatermasks",
    color: "#00C7BE",
    background_color: "#C2EAE8",
    thing_style: "album",
    formats: ["album"],
    wikidata_id: "Q482994",
    sections: [],
    menu: [
      {
        text: "Add to Listenlist",
        text_active: "Listenlist",
        web_icon: <Sparkle />,
        icon: "sparkles",
      },
      {
        text: "Mark as Listening",
        text_active: "Listening",
        web_icon: <Sparkle />,
        icon: "beats.headphones",
      },
      {
        text: "Mark as Abandoned",
        text_active: "Abandoned",
        web_icon: <Sparkle />,
        icon: "headphones",
      },
    ],
    header_image_width: 0.65,
    image_aspect_ratio: 1,
    thing: { id: "785c46c0-6671-4f72-8659-7848befc5d77" },
    thing_props_map: [
      // { label: "Songs", wikidata_id: "P361", inverse: 0, show: 'things', max: 10, title: 'name', hasQualifierWikidataId: 'P453' },
      { label: "Songs", wikidata_id: "P175", inverse: 0, show: "things" },
    ],
  },
};
