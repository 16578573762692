import { categoriesList, formatMappings } from "./categories";

export function arrayMove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export function formatMoneyNumber(num) {
  if (num < 10000) {
    return num;
  }
  if (num < 1000000) {
    return Math.round(num / 1000) + "K";
  }
  let millionValue = (num / 1000000).toFixed(1);
  if (millionValue.endsWith(".0")) {
    millionValue = millionValue.slice(0, -2);
  }
  return millionValue + "M";
}

export function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hr ${remainingMinutes} min`;
}

export function hexToRgbA(hex, a) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + a + ")";
  }
  return "rgba(0, 0, 0, 1)";
  // throw new Error('Bad Hex');
}

export function getFormat(type_of) {
  let format = "regular";
  if (typeof type_of === "string") {
    for (const category in categoriesList) {
      const list = categoriesList[category]["formats"];
      if (list.some((name) => type_of?.toLowerCase() == name?.toLowerCase())) {
        return category;
      }
    }
    return format;
  } else {
    for (const category in categoriesList) {
      const list = categoriesList[category]["formats"];

      if (list?.some((name) => type_of?.some((obj) => obj?.name?.toLowerCase() === name?.toLowerCase()))) {
        return category;
      }
    }
    return format;
  }
  return format;
}

export function getCallToActions(categories = {}, thing) {
  let categoryFormat = getFormat(thing?.type_of);
  let call_to_action = categories[categoryFormat] ? JSON.parse(JSON.stringify(categories[categoryFormat])) : { menu: [] }; //Pull from redux
  return call_to_action;
}

export function getCategory(categories = {}, thing) {
  let categoryFormat = getFormat(thing?.type_of);
  let category = categories[categoryFormat] ? JSON.parse(JSON.stringify(categories[categoryFormat])) : { menu: [] }; //Pull from redux
  let things = category["things"] || [];

  let thingIndex = things.findIndex((t) => t.id === thing.id);
  if (thingIndex > 0) {
    let { current_stage = -1 } = things[thingIndex];
    if (current_stage >= 0) {
      // let category = category['menu'][current_stage] || {}
      // return category
    }
  }
  return category;
}

export function getCategoryFromType(categories = {}, type) {
  let categoryFormat = getFormat([type]);
  let category = categories[categoryFormat] ? JSON.parse(JSON.stringify(categories[categoryFormat])) : { menu: [] }; //Pull from redux
  return category;
}

export function getCategoryThings(categories = {}, thing) {
  for (let c in categories) {
    let cat = categories[c];
    if (cat.wikidata_id == thing.wikidata_id) {
      if (cat.things) {
        return cat.things;
      }
    } else {
      // console.log("NO MATCH")
      continue;
    }
  }
  return [];
}

export function transformThings(thingsConnection = {}) {
  let things = [];
  if (thingsConnection?.edges) {
    // console.log("HERE123", thingsConnection.edges)
    things = thingsConnection.edges.map((e) => {
      return { ...e, ...e.node };
    });
  }
  return things;
}
export const transformDetails = (thingDetails) => {
  // console.log("thingDetails", thingDetails);
  if (!thingDetails) {
    return {}; // Return an empty object if detailsConnection is null or undefined
  }

  let detailsByWikidataId = {};
  for (let d in thingDetails) {
    let detail = thingDetails[d];
    // console.log("DETT", detail);

    let { properties: property } = detail;
    // console.log("PROPPP", property);
    if (property && property.wikidata_id) {
      const wikidataId = property.wikidata_id;
      if (!detailsByWikidataId[wikidataId]) {
        detailsByWikidataId[wikidataId] = {};
        detailsByWikidataId[wikidataId]["details"] = [];
        detailsByWikidataId[wikidataId]["things"] = [];
      }
      // console.log("HEREE13", wikidataId);
      // let { qualifiers = [] } = detail;
      // let nConnections = {
      //   edges: JSON.parse(JSON.stringify(node.thingsConnection.edges)),
      // };
      let element = {
        ...(detail.media || {}),
        ...(detail.dataThing || {}),
      };
      // console.log("EEEE", element);
      // for (let n in nConnections.edges) {
      //   nConnections.edges[n]["qualifiers"] = qualifiers;
      // }
      detailsByWikidataId[wikidataId]["things"] = [...detailsByWikidataId[wikidataId]["things"], element];
      detailsByWikidataId[wikidataId]["details"].push(detail);
    }
  }
  // console.log("detailsByWikidataId", JSON.stringify(detailsByWikidataId));
  return detailsByWikidataId;
};

// export const transformDetails = (detailsConnection) => {
//   if (!detailsConnection) {
//     return {}; // Return an empty object if detailsConnection is null or undefined
//   }

//   let { edges } = detailsConnection;
//   let detailsByWikidataId = {};
//   for (let d in edges) {
//     let initDetail = edges[d];
//     let { node = {} } = initDetail;
//     let detail = { ...initDetail, ...node };
//     delete detail["node"];
//     if (node.property && node.property.wikidata_id) {
//       const wikidataId = node.property.wikidata_id;
//       if (!detailsByWikidataId[wikidataId]) {
//         detailsByWikidataId[wikidataId] = {};
//         detailsByWikidataId[wikidataId]["details"] = [];
//         detailsByWikidataId[wikidataId]["things"] = [];
//       }
//       let { qualifiers = [] } = detail;
//       let nConnections = { edges: JSON.parse(JSON.stringify(node.thingsConnection.edges)) };
//       for (let n in nConnections.edges) {
//         nConnections.edges[n]["qualifiers"] = qualifiers;
//       }
//       detailsByWikidataId[wikidataId]["things"] = [...detailsByWikidataId[wikidataId]["things"], ...transformThings(nConnections)];
//       detailsByWikidataId[wikidataId]["details"].push(detail);
//     }
//   }
//   // console.log("detailsByWikidataId", JSON.stringify(detailsByWikidataId))
//   return detailsByWikidataId;
// };

export const extractInitials = (str) => {
  if (!str) return;
  const words = str.trim().split(/\s+/);
  const firstInitial = words[0] ? words[0][0].toUpperCase() : "";
  const lastInitial = words.length > 1 ? words[words.length - 1][0].toUpperCase() : "";
  return firstInitial + (words.length > 1 ? lastInitial : "");
};

export function darkenHexColor(hex, percent) {
  if (!hex) return;
  const amount = Math.round(255 * (percent / 100));
  const R = (parseInt(hex.substring(1, 3), 16) - amount).toString(16).padStart(2, "0");
  const G = (parseInt(hex.substring(3, 5), 16) - amount).toString(16).padStart(2, "0");
  const B = (parseInt(hex.substring(5, 7), 16) - amount).toString(16).padStart(2, "0");
  return `#${R}${G}${B}`;
}
